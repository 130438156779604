// import logo from './logo.svg';
import './App.css';
// import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
// import Main from './pages/Main';
import Home from './pages/Home';
import About from './pages/About';
import LocomotiveScroll from 'locomotive-scroll';
import Contact from './pages/Contact';
// import Services from './pages/Services';
// import Service from './pages/Service';
import Portfolio from './pages/Portfolio';
import Services from './pages/Services';
// import Portfolio from './pages/Portfolio';
// import GetInTouch from './pages/GetInTouch';


function AppRouter() {
  const locomotiveScroll = new LocomotiveScroll();
  return (
    <Router>
      <div className=''>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          {/* <Route exact path="/services" element={<Service />} /> */}
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/portfolio" element={<Portfolio />} />
          {/* <Route exact path="/portfolio" element={<Portfolio />} /> */}

          {/* <Route exact path="/about" element={<About />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default AppRouter;
