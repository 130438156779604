import { motion } from 'framer-motion'
import React from 'react'
import { Parallax } from 'react-scroll-parallax'

const WebPortfolio = () => {

    const WebWork = [
        {
            'img': "/portfolio/Bespoke.png",
            'heading': 'BespokeByNSH',
            'link': 'https://www.bespokebynsh.com/',
        },
        {
            'img': "/portfolio/laundrokartpng.png",
            'heading': 'Laundrokart',
            'link': 'https://www.laundrokart.com/',
        },
        {
            'img': "/portfolio/modelversein.png",
            'heading': 'Model Verse',
            'link': 'https://modelverse.in/',
        },
        {
            'img': "/portfolio/unityhealthsystemcom.png",
            'heading': 'Unity Health System',
            'link': 'https://unityhealthsystem.com/',
        },
        {
            'img': "/portfolio/sumannathwanipng.png",
            'heading': 'Suman Nathwani',
            'link': 'https://sumannathwani.com/',
        },
        {
            'img': "/portfolio/primecarealliancecom.png",
            'heading': 'Prime Care Alliance',
            'link': 'https://www.primecarealliance.com/',
        },
        {
            'img': "/portfolio/tipriyoai.png",
            'heading': 'Tipriyo',
            'link': 'https://tipriyo.ai/',
        },
        {
            'img': "/portfolio/tatvamconceptspng.png",
            'heading': 'Tatvam Concepts',
            'link': 'https://tatvamconcepts.com/',
        },
        {
            'img': "/portfolio/trioscopng.png",
            'heading': 'Triosco',
            'link': 'https://triosco.com/',
        },
        {
            'img': "/portfolio/staffision.png",
            'heading': 'Staffision',
            'link': 'https://staffision.com/',
        },
        {
            'img': "/portfolio/up-bringing.png",
            'heading': 'Up-bringing',
            'link': 'https://upbringing.vercel.app/',
        },
        {
            'img': "/portfolio/chandbalijewels.png",
            'heading': 'Chandbali Jewels',
            'link': 'https://www.chandbalijewels.com/',
        },
    ]
    return (
        <div>
            <div className='grid sm:grid-cols-2 lg:grid-cols-2 items-center gap-1'>
                {WebWork.map((data, index) => {
                    return (
                        <Parallax
                        // opacity={['2', '0']}
                        // scale={['1', '0.7']}
                        >
                            <motion.div
                                // initial={{ scale: 0, y: -100 }}
                                // whileInView={{ scale: 1, y: 0 }}
                                // transition={{ duration: 0.3, delay: index * 0.1 }}
                                // viewport={{ once: true }}
                                class="relative lg:w-full w-[80%] h-[50vw] border border-white bg-black [background:radial-gradient(50%_50%_at_50%_50%,#404040,#0a0a0a)] m-auto overflow-hidden group p-2 z-0"
                            >
                                <div
                                // class="circle absolute h-[7em] w-[7em] -top-[2.5em] -right-[0em] rounded-full bg-[#FF9800] group-hover:scale-[800%] duration-500 z-[-10] op"
                                ></div>

                                <button
                                    class="text-[1em] absolute bottom-[1em] left-[1em] text-black group-hover:text-[#FF9800] duration-500"
                                >
                                    <a href={data.link}
                                        class="relative before:h-[0.16em] before:absolute before:w-full before:content-[''] before:bg-[#FF9800] group-hover:before:bg-[#FF9800] duration-300 before:bottom-0 before:left-0" target="_blank" alt='work'

                                    >More Info</a>
                                    <i class="fa-solid fa-arrow-right"></i>
                                </button>

                                <h1
                                    class="h-[80vw] z-10 font-[extrabold] text-black group-hover:text-white duration-500 lg:text-[5em]"
                                >
                                    {data.heading}
                                </h1>
                                {/* <div className='relative'> */}
                                <img className='-z-0 group-hover:-z-10 group-hover:opacity-50 ease-in-out duration-100 transition-transform absolute w-[100%] lg:top-[20%] top-2 left-0 lg:left-[20%]' src={data.img} alt='services' />
                                {/* </div> */}
                            </motion.div>
                        </Parallax>
                    )
                })}
            </div>
        </div>
    )
}

export default WebPortfolio
