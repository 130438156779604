import React from 'react'
import GetInTouch from '../landingPage/GetInTouch'
import Layout from '../components/Layout/Layout'

const Contact = () => {
    return (
        <Layout title={'siteScript-Contact'}>
            <div className="lg:bg-contain py-[20vh]"
                style={{
                    // backgroundImage: `url(cornered-stairs.svg)`,
                    // backgroundColor: "#B99470"
                }}
            >
                <GetInTouch />
            </div>
        </Layout>
    )
}

export default Contact
