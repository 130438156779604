import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';

const Stats = ({ label, value }) => {
    const [count, setCount] = useState(0);
    const controls = useAnimation();

    useEffect(() => {
        controls.start({ opacity: 1, scale: 1, transition: { duration: 0.5 } });
        const interval = setInterval(() => {
            setCount((prevCount) => {
                if (prevCount >= value) {
                    clearInterval(interval); // Stop the counting when value is reached
                    return value;
                } else {
                    return prevCount + 1;
                }
            });
        }, 50);
        return () => clearInterval(interval);
    }, [controls, value]);

    return (
        <div className="w-fit flex items-center justify-center space-x-2">
            <div className="font-bold">{label}</div>
            <motion.div
                className=" text-[#FF9800]"
                initial={{ opacity: 0, scale: 0 }}
                animate={controls}
            >
                {count}+
            </motion.div>
        </div>
    );
};

export default Stats;
