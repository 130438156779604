import React from 'react'
import { BsFacebook, BsInstagram, BsLinkedin, BsFillTelephoneFill } from 'react-icons/bs';
import { FaSquareXTwitter } from 'react-icons/fa6';
import { HiMail } from 'react-icons/hi';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className="pt-24 mx-auto w-full text-white bg-black [background:radial-gradient(50%_50%_at_50%_50%,#404040,#0a0a0a)]  border-t border-neutral-800">
            <div className="grid gap-16 row-gap- mb-8 lg:grid-cols-6 md:px-24 lg:px-8 px-4">
                <div className="md:max-w-md lg:col-span-2">
                    <Link to='/' className='flex items-center h-[5vh]' aria-label="Home">
                        <img className="lg:w-[5vw] w-[20vw] items-center" src="sitescriptwhitelogo.png" alt="Logo" />
                        <h2 className='lg:text-[2vw] md:text-[2vw] text-[6vw]'>SiteScript</h2>
                        {/* <h2 className='font-bold lg:text-[2vw] text-[6vw] text-neutral-500 font-[poppins]'>siteScript</h2> */}
                    </Link>
                    {/* <div className="mt-4 lg:max-w-sm">
                        <p className= "text-gray-800">
                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                            accusantium doloremque laudantium, totam rem aperiam.
                        </p>
                        <p className="mt-4 text-gray-800">
                            Eaque ipsa quae ab illo inventore veritatis et quasi architecto
                            beatae vitae dicta sunt explicabo.
                        </p>
                    </div> */}
                </div>
                <div className="grid grid-cols-2 lg:col-span-4 md:grid-cols-2 font-[poppins] font-extralight">
                    <div className=''>
                        <p className="font-semibold text-[#FF9800] tracking-wider lg:text-[1.5vw] text-[6vw]">
                            Links
                        </p>
                        <ul className="mt-2 space-y-[2vh] lg:text-[1.2vw] text-[3.2vw] drop-shadow-[0px_0px_2px_var(--tw-shadow-color)] shadow-neutral-500">
                            <li>
                                <a
                                    href="/about"
                                    className="text-white font-extralight transition-colors hover:text-neutral-500 hover:font-extralight duration-300 hover:text-deep-purple-accent-300 tracking-wide "
                                >
                                    About
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/services"
                                    className="text-white font-extralight transition-colors hover:text-neutral-500 hover:font-extralight duration-300 hover:text-deep-purple-accent-300 tracking-wide"
                                >
                                    Services
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/portfolio"
                                    className="text-white font-extralight transition-colors hover:text-neutral-500 hover:font-semibold duration-300 hover:text-deep-purple-accent-300 tracking-wide"
                                >
                                    Our Work
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/contact"
                                    className="text-white font-extralight transition-colors hover:text-neutral-500 hover:font-extralight duration-300 hover:text-deep-purple-accent-300 tracking-wide"
                                >
                                    Contact
                                </a>
                            </li>

                        </ul>
                    </div>

                    <div className=''>
                        <p className="font-semibold text-[#FF9800] tracking-wider lg:text-[1.5vw] text-[6vw]">Contact</p>
                        <ul className="mt-2 space-y-[2vh] lg:text-[1.2vw] text-[3.2vw] drop-shadow-[0px_0px_2px_var(--tw-shadow-color)] shadow-neutral-500">
                            <li>
                                <a
                                    href='tel: +91 9599791185'
                                    className=" text-white font-extralight flex items-center transition-colors hover:text-neutral-500 hover:font-extralight duration-300 hover:text-deep-purple-accent-300 tracking-wide"
                                >
                                    <BsFillTelephoneFill /> +91 9599791185
                                </a>
                            </li>
                            <li>
                                <a href='mailto: info@sitescript.in' className="flex text-white font-extralight items-center hover:text-neutral-500 hover:font-extralight  lg:tracking-wide tracking-tighter">
                                    info@sitescript.in</a>
                            </li>
                            <li>
                                <a href='mailto: namanwebworks@gmail.com' className="flex text-white font-extralight items-center hover:text-neutral-500 hover:font-extralight  lg:tracking-wide tracking-tighter">
                                    namanwebworks@gmail.com
                                </a>
                            </li>
                        </ul>
                    </div>


                </div>
            </div>
            <div className="justify-between pt-5 pb-5 border-t border-white">
                <div className="w-fit mx-auto">
                    <div className="flex justify-start space-x-[5vw]">
                        <a href="https://www.facebook.com/profile.php?id=61553868025255" target="_blank" rel="noreferrer" className="rounded-full w-6 inline-block mx-1 text-center pt-1 ">
                            <BsFacebook className='w-full h-full text-white hover:text-[#FF9800]' />
                        </a>
                        <a href="https://www.instagram.com/_sitescript_/" target="_blank" rel="noreferrer" className="rounded-full w-6 inline-block mx-1 text-center pt-1">
                            <BsInstagram className='w-full h-full text-white hover:text-[#FF9800]' />
                        </a>
                        <a href="https://twitter.com/_siteScript_" target="_blank" rel="noreferrer" className="rounded-full w-6 inline-block mx-1 text-center pt-1">
                            <FaSquareXTwitter className='w-full h-full text-white hover:text-[#FF9800]' />
                        </a>
                        <a href="https://www.linkedin.com/company/sitescriptitsolution/" target="_blank" rel="noreferrer" className="rounded-full w-6 inline-block mx-1 text-center pt-1">
                            <BsLinkedin className='w-full h-full text-white hover:text-[#FF9800]' />
                        </a>
                    </div>
                </div>
            </div>
        </div >
    );

}

export default Footer
