import React from 'react'
import Layout from '../components/Layout/Layout';
import ServiceComponent from '../components/ServiceComponent';
import { motion } from 'framer-motion';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

const Services = () => {
    const services = [
        {
            'img': '/images/Saly-11.webp',
            'title': 'Website Design & Development',
            'desc': '',
        },
        {
            'img': '/images/Saly-39.png',
            'title': 'AI Solutions',
            'desc': '',
        },
        {
            'img': '/images/Saly-43.png',
            'title': 'Brand Designing',
            'desc': '',
        },
        {
            'img': '/images/Saly-13.png',
            'title': 'Graphic Designing',
            'desc': '',
        },
        {
            'img': '/images/Saly-12.webp',
            'title': 'Social Media Marketing',
            'desc': '',
        },
        {
            'img': '/images/Saly-24.png',
            'title': 'Video Editing',
            'desc': '',
        },
    ]

    return (
        <Layout title={'siteScript-Services'}>
            <ParallaxProvider>
                <div className='mx-auto w-fit font-[lightpoppins]' id='container'>
                    <div className='lg:bg-contain bg-cover h-full' style={{
                        // backgroundImage: `url(cornered-stairs.svg)`,
                        // backgroundColor: "#B99470"
                    }}
                    >
                        {/* <ServiceComponent /> */}



                        <div className='w-full h-full relative text-neutral-800 lg:py-[20vh] py-[20vh] bg-black [background:radial-gradient(50%_50%_at_50%_50%,#d4d4d4,#0a0a0a)] overflow-hidden'>
                            {/* <TextParallaxContentExample/> */}
                            <div className='flex'>

                                <h2 className='lg:w lg:text-[8vw] text-[7vw] font-[lightpoppins] text-white lg:text-start text-center space-y-5 lg:mt-[20vh] mb-[20vh] px-[5vw]'>We Do...</h2>

                                {/* <img className='w-[50%]' src='/images/Saly-6.webp' /> */}

                            </div>

                            <div className='grid sm:grid-cols-2 lg:grid-cols-2 items-center gap-1'>
                                {services.map((data, index) => {
                                    return (
                                        <Parallax
                                        // opacity={['2', '0']}
                                        // scale={['1', '0.7']}
                                        >
                                            <motion.div
                                                // initial={{ scale: 0, y: -100 }}
                                                // whileInView={{ scale: 1, y: 0 }}
                                                // transition={{ duration: 0.3, delay: index * 0.1 }}
                                                // viewport={{ once: true }}
                                                class="relative lg:w-full w-[80%] lg:h-[50vw] border border-white bg-black [background:radial-gradient(50%_50%_at_50%_50%,#404040,#0a0a0a)] m-auto overflow-hidden group p-2 z-0"
                                            >
                                                <div
                                                // class="circle absolute h-[7em] w-[7em] -top-[2.5em] -right-[0em] rounded-full bg-[#FF9800] group-hover:scale-[800%] duration-500 z-[-10] op"
                                                ></div>

                                                <button
                                                    class="text-[1em] absolute bottom-[1em] left-[1em] text-black group-hover:text-[#FF9800] duration-500"
                                                >
                                                    <span
                                                        class="relative before:h-[0.16em] before:absolute before:w-full before:content-[''] before:bg-[#FF9800] group-hover:before:bg-[#FF9800] duration-300 before:bottom-0 before:left-0"
                                                    >More Info</span
                                                    >
                                                    <i class="fa-solid fa-arrow-right"></i>
                                                </button>

                                                <h1
                                                    class="h-[80vw] z-10 font-[extrabold] text-black group-hover:text-white duration-500 lg:text-[5em]"
                                                >
                                                    {data.title}
                                                </h1>
                                                {/* <div className='relative'> */}
                                                <img className='-z-0 group-hover:-z-10 group-hover:opacity-70 ease-in-out duration-100 transition-transform absolute w-[100%] top-0 lg:left-[10%]' src={data.img} alt='services' />
                                                {/* </div> */}
                                            </motion.div>
                                        </Parallax>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </ParallaxProvider>
        </Layout >

    )
}

export default Services
