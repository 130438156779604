import { motion } from 'framer-motion'
import React from 'react'
import { Parallax } from 'react-scroll-parallax'

const BrandPostPortfolio = () => {

    const WebWork = [
        {
            'img': "/portfolio/brandingpost1.jpg",
        },
        {
            'img': "/portfolio/brandingpost2.jpg",
        },
        {
            'img': "/portfolio/brandingpost3.jpg",
        },
        {
            'img': "/portfolio/brandingpost4.jpg",
        },
        {
            'img': "/portfolio/brandingpost5.jpg",
        },
        {
            'img': "/portfolio/brandingpost6.jpg",
        },
        {
            'img': "/portfolio/brandingpost7.jpg",
        },
        {
            'img': "/portfolio/brandingpost8.jpg",
        },
        {
            'img': "/portfolio/brandingpost9.jpg",
        },
        {
            'img': "/portfolio/brandingpost10.jpg",
        },
        {
            'img': "/portfolio/brandingpost11.jpg",
        },
        {
            'img': "/portfolio/brandingpost12.jpg",
        },
    ]
    return (
        <div>
            <div className='grid sm:grid-cols-2 lg:grid-cols-2 items-center gap-1'>
                {WebWork.map((data, index) => {
                    return (
                        <Parallax
                        // opacity={['2', '0']}
                        // scale={['1', '0.7']}
                        >
                            <motion.div
                                // initial={{ scale: 0, y: -100 }}
                                // whileInView={{ scale: 1, y: 0 }}
                                // transition={{ duration: 0.3, delay: index * 0.1 }}
                                // viewport={{ once: true }}
                                class="relative lg:w-full w-[80%] h-[50vw] border border-white bg-black [background:radial-gradient(50%_50%_at_50%_50%,#404040,#0a0a0a)] m-auto overflow-hidden group p-2 z-0"
                            >
                                <div
                                // class="circle absolute h-[7em] w-[7em] -top-[2.5em] -right-[0em] rounded-full bg-[#FF9800] group-hover:scale-[800%] duration-500 z-[-10] op"
                                ></div>

                                {/* <button
                                    class="text-[1em] absolute bottom-[1em] left-[1em] text-black group-hover:text-[#FF9800] duration-500"
                                >
                                    <a href={data.link}
                                        class="relative before:h-[0.16em] before:absolute before:w-full before:content-[''] before:bg-[#FF9800] group-hover:before:bg-[#FF9800] duration-300 before:bottom-0 before:left-0"
                                    >More Info</a>
                                    <i class="fa-solid fa-arrow-right"></i>
                                </button>

                                <h1
                                    class="h-[80vw] z-10 font-[extrabold] text-black group-hover:text-white duration-500 lg:text-[5em]"
                                >
                                    {data.heading}
                                </h1> */}
                                {/* <div className='relative'> */}
                                <img className='-z-0 group-hover:-z-10 group-hover:opacity-50 ease-in-out duration-100 transition-transform absolute w-[100%] lg:top-[20%] top-2 left-0 lg:left-0' src={data.img} alt='services' />
                                {/* </div> */}
                            </motion.div>
                        </Parallax>
                    )
                })}
            </div>
        </div>
    )
}

export default BrandPostPortfolio
