import React from 'react'
import { MaskText } from '../components/MaskText'
import { motion } from 'framer-motion'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'

const QnA = () => {
    const qna = [
        {
            'title': "What types of websites can you create?",
            'ans': "We specialize in creating various types of websites, such as corporate websites, e-commerce platforms, blogs, portfolios, landing pages, and custom web applications. Whether you need a simple informational website or a complex, feature-rich platform, we have the expertise to deliver."
        },
        {
            'title': "Can you redesign an existing website?",
            'ans': "Yes, we offer website redesign services to give your existing website a fresh look, improve functionality, enhance user experience, and incorporate modern design trends. Our team will assess your current website, discuss your goals, and create a tailored redesign plan."
        },
        {
            'title': "How can I request a quote or estimate for your services?",
            'ans': "You can request a quote or estimate by filling out our online contact form, sending us an email, or giving us a call. One of our representatives will get in touch with you promptly."
        },
        {
            'title': 'What are your payment options?',
            'ans': "We accept various payment methods, including cards, checks, upi, paypal & bank transfers."
        },
        {
            'title': "Can you integrate third-party tools and platforms into my website?",
            'ans': "Absolutely, we have experience integrating various third-party tools, APIs, and platforms to enhance your website's functionality. Whether you need payment gateways, CRM systems, social media integration, or other services, we can seamlessly integrate them into your website."
        },
        {
            'title': "Is SEO included in your website services?",
            'ans': "Yes, we offer SEO services to improve your website's visibility in search engine results, drive organic traffic, and boost rankings. Our SEO strategies include keyword research, on-page optimization, content creation, link building, and analytics tracking to maximize your online presence."
        },
    ]
    return (
        <ParallaxProvider>

            <div className='bgneutral-950 py-[12vh] text-neutral-800 font-[poppins] px-[5vw] '>
                <h2 className='text-neutral-800 lg:text-[3vw] uppercase font-semibold'
                >
                    <MaskText text='Frequently Asked Questions' />
                </h2>
                <div className=''
                    // data-scroll data-scroll-speed='0.2'
                    >
                    {qna.map((data, index) => {
                        return (

                            <Parallax
                                // opacity={['1.5', '0']}
                                // scale={['1', '0.8']}
                                // whileInView={{ y: 0 }}
                                // transition={{ duration: 0.1, }}
                                // viewport={{ once: true }}
                                // initial={{ y: 200 }}
                                className=' transition-all lg:w-[80%] mx-auto bg-neutral-950 p-[5vh] rounded-lg my-[2vh]'>
                                <h1 className='text-justify text-[#FF9800] lg:text-[4vh] text-[2vh]  font-[lightpoppins]'><MaskText text={data.title} /></h1>
                                <p className='text-justify text-neutral-200 font-[lightpoppins] font-normal'><MaskText text={data.ans} /></p>
                            </Parallax>
                        )
                    })}

                </div>
            </div>
        </ParallaxProvider>

    )
}

export default QnA
