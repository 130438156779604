import React from 'react'
import styled, { keyframes, css } from "styled-components";
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaReact, FaShopify, FaWordpress, FaPhp } from "react-icons/fa6";
import { FaNode } from "react-icons/fa";
import { GiArtificialIntelligence } from "react-icons/gi";
import { SiTaichigraphics } from "react-icons/si";
const ImageCarousel = () => {

    const { ref, inView } = useInView({ triggerOnce: true });

    const variants = {
        hidden: { opacity: 0 },
        show: {
            opacity: inView ? 1 : 0,
            transition: {
                staggerChildren: 0.2,
            },
        },
    };

    const item = {
        hidden: {
            opacity: 0,
            y: 100,
        },
        show: {
            opacity: 1,
            y: inView ? 0 : 100,
            transition: {
                duration: 0.8,
            },
        },
    };

    const variants2 = {
        hidden: { opacity: 0 },
        show: {
            opacity: inView ? 1 : 0,
            transition: {
                staggerChildren: 0.1,
            },
        },
    };

    const items2 = {
        hidden: {
            opacity: 0,
            y: 100,
        },
        show: {
            opacity: 1,
            y: inView ? 0 : 100,
            transition: {
                duration: 1,
            },
        },
    };

    const row1 = [
        // <FaReact />,
        // <FaNode />,
        // <GiArtificialIntelligence />,
        // <FaShopify />,
        // <FaWordpress />,
        // <SiTaichigraphics />,
        'Web Tech',
        'AI Solutions',
        "Brand Design",
        'Graphic Design',
        'Video Editing',
        "Social Media Marketing",
        // "",
        // "ModelsDone/Model (27).png",

    ];
    const row2 = [
        'Web Tech',
        'AI Solutions',
        "Brand Design",
        'Graphic Design',
        'Video Editing',
        "Social Media Marketing",
        // "ModelsDone/Model (27).png",

    ];
    return (
        <div className='xl:my-[] scroll-smooth overflow-hidden'>

            {/* Image Carousel */}

            <motion.div
                variants={item}
                className='text-white'
            >
                <AppContainer className=''>
                    <Wrapper className='Wrapper'>
                        <Marquee className='Marquee'>
                            <MarqueeGroup className=' text-neutral-400 lg:text-base md:text-[3vw]'>
                                {row1.map((el) => (
                                    <div>

                                        {el}
                                    </div>
                                ))}
                            </MarqueeGroup>
                            <MarqueeGroup className=' text-neutral-400 lg:text-base md:text-[3vw]'>
                                {row2.map((el1) => (
                                    <div>

                                        {el1}
                                    </div>
                                ))}
                            </MarqueeGroup>
                        </Marquee>
                    </Wrapper>
                </AppContainer>
            </motion.div>
        </div>
    )
}

export default ImageCarousel

const AppContainer = styled.div`
  width: 90vw;
  height: ;
  color: #000000;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Marquee = styled.div`
  display: flex;
  overflow: hidden;
  user-select: none;
  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  );
`;

const scrollX = keyframes`
  from {
    left: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const common = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  white-space: nowrap;
  width: 100%;
  animation: ${scrollX} 20s linear infinite;  
`;

const style = {
    '@media (max-width: 600px)': {
        width: '100%',
        justify: 'space-evenly',
    },
}

const MarqueeGroup = styled.div`
  ${common}
  ${style}
`;

const ImageGroup = styled.div`
  display: ;
  place-items: ;
`;

const ResponsiveImage = {
    '@media (max-width: 600px)': {
        width: '90vw',
        height: '10vh',
        // padding: '0 0'
    },
}

const Image1 = {
    width: '10vw',
    height: '30vh',
    padding: '0 '
}

const Image = styled.img`
  ${Image1}
  ${ResponsiveImage}
`;