import React, { useRef } from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Typewriter from 'typewriter-effect';
import Stats from '../components/Stats';
import { MaskText } from '../components/MaskText';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import ImageCarousel from './ImageCarousel';

const Banner = () => {
    const settings = {
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const banner = [
        {
            'img': 'images/7.png',
        },
        {
            'img': 'images/6.png',
        },
        {
            'img': 'images/1.png',
        },
    ]

    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
    });
    const x = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);

    return (
        <ParallaxProvider className='lg:bg- bg-cover'
            style={{
                backgroundImage: `url(cornered-stairs.svg)`,
            }}>
            <div className='relative'
            >
                {/* <div className='a'>
                </div> */}
                <div class="absolute inset-0 -z-10 h-full w-full bg-black [background:radial-gradient(50%_50%_at_50%_50%,#404040,#0a0a0a)]">
                </div>
                <div className="w-full lg:flx overflow-x-hidden my-auto font-[lightpoppins]" id='services'

                >
                    <motion.div

                        initial={{ opacity: 0, x: 100 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1.2, delay: 1 }}
                        className='absolute lg:top-[25%] top-[15%] lg:left-[58%] left-[70%] animate-pulse'
                    >
                        <img className='' src='images/abstract10g.png' />
                    </motion.div>

                    <motion.div

                        initial={{ opacity: 0, x: -100 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1.2, delay: 1 }}
                        className='absolute lg:top-[45%] md:top-[45%] top-[45%] lg:left-[30%] md:left-[10%] left-[0%] animate-pulse'
                    >
                        <img className='' src='images/abstract1g.png' />

                    </motion.div>

                    <div
                        className='px-[5vw] lg:py-[10vh] py-[10] lg:w-[100%] w-full h-screen mx-auto text-neutral-200 space-y-[3vh] lg:text-[5.5vw] text-[4vh] font-[poppinsregular]'
                        data-scroll data-scroll-speed='0.5'>
                        <motion.h2
                            className='absolute top-[25%] md:left-[25%] w-fit mx-auto lg:text-start' id='signupHeading' >
                            <MaskText text='We' />
                        </motion.h2>
                        <motion.div
                            className='mx-auto lg:text-start'>
                            <Parallax
                                className='absolute top-[25%] lg:left-[35%] md:left-[37%] left-[22%] mx-auto text-center tracking-widest' id='signupsubHeading'>
                                <MaskText text='Design' />
                            </Parallax>
                            <Parallax
                                className='absolute lg:top-[45%] top-[35%] lg:left-[40%] md:left-[37%] left-[18%] mx-auto text-center tracking-widest' id='signupsubHeading'>
                                <MaskText text='Develop' />
                            </Parallax>
                            <Parallax
                                className='absolute lg:top-[45%] top-[35%] lg:left-[67%] md:left-[70%] left-[62%] mx-auto text-center tracking-widest' id='signupsubHeading'>
                                <MaskText text='Deliver' />
                            </Parallax>
                        </motion.div>


                        <motion.div
                            initial={{ scale: 0 }}
                            whileInView={{ scale: 1 }}
                            transition={{ delay: 0.3, duration: 0.5 }}
                            className="absolute lg:top-[20vh] top-[60vh] lg:left-[10%] left-[20%] mx-auto w-fit flex md:text-[2vw] lg:text-[1vw] text-[3vw] font-[poppins] space-x-[2vw]">
                            <Stats label="- Success Projects" value={25} />
                        </motion.div>

                        <motion.div
                            initial={{ scale: 0 }}
                            whileInView={{ scale: 1 }}
                            transition={{ delay: 0.5, duration: 0.5 }}
                            className="absolute lg:top-[25vh] top-[65vh] lg:left-[80%] left-[50%] mx-auto w-fit flex md:text-[2vw] lg:text-[1vw] text-[3vw] font-[poppins] space-x-[2vw]">
                            <Stats label="- Happy Clients" value={20} />
                        </motion.div>

                        <motion.div
                            initial={{ scale: 0 }}
                            whileInView={{ scale: 1 }}
                            transition={{ delay: 0.7, duration: 0.5 }}
                            className="absolute lg:top-[65vh] top-[70vh] lg:left-[20%] left-[20%] mx-auto w-fit flex md:text-[2vw] lg:text-[1vw]  text-[3vw] font-[poppins] space-x-[2vw]">
                            <Stats label="- Countries Served" value={5} />
                        </motion.div>

                        {/* Carousel  */}
                        <motion.div className='absolute top-[75vh] overflow-hidden'
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.8, duration: 0.5 }}
                        >
                            <ImageCarousel />
                        </motion.div>
                    </div>
                </div>
            </div>

        </ParallaxProvider>
    )
}

export default Banner
