import React, { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {

    const pathName = useLocation();

    const [isOpen, setIsOpen] = useState(false);
    let menuRef = useRef();
    useEffect(() => {
        let handler = (e) => {
            if (!menuRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler);
        }
    })

    const navbar = [
        // { 'id': '1', 'nav': 'Intro', 'link': '#' },
        { 'id': '1', 'nav': 'About Us', 'link': '/about' },
        { 'id': '2', 'nav': 'Services', 'link': '/services' },
        { 'id': '3', 'nav': 'Our Work', 'link': '/portfolio' },
        { 'id': '4', 'nav': "Let's Talk", 'link': '/contact' },
        // { 'id': '4', 'nav': 'Pricing', 'link': '/pricing' },
        // { 'id': '4', 'nav': 'Contact Us', 'link': '/contack' },
    ];



    return (
        <div className='pt-10 lg:px-0 px-[2vw]'>
            <nav className="scroll-smooth lg:w-[80vw] w-[90vw] mx-auto flex items-center justify- md:justify-center lg:py-0 py-2 border border-neutral-800 md:space-x-10 rounded-full bg-neutral-950 lg:px-0 px-[5vw] font-[poppins] font-semibold">
                <div className="flex items-center lg:flex-initial flex-1 md:h-auto h-[5vh]">
                    <div className="flex items-center justify-between lg:w-full md:w-auto overflow-hidden text-white">
                        <Link to='/' className='flex items-center w-fit' aria-label="Home">
                            <img className="lg:w-[5vw] w-[15vw] items-center" src="sitescriptwhitelogo.png" alt="Logo" />
                            <h2 className='lg:text-[1.2vw] md:text-[2vw] text-[5vw]'>SiteScript</h2>
                        </Link>

                    </div>
                </div>
                <div className="hidden lg:flex md:space-x-10 text-base items-center">
                    {navbar.map((data, index) => {
                        return (
                            <motion.div
                                initial={{ scale: 0, opacity: 0, x: 100, y: -100 }}
                                animate={{ scale: 1, opacity: 1, x: 0, y: 0 }}
                                transition={{ delay: index * 0.1, duration: 0.2 }}


                                className={`lg:text-[1.2vw] md:text-[1.5vw] items-end hover:text-[#FF9800] transition-all duration-150 ease-in-out hover:tracking-widest scroll-smooth hover:scroll-auto p-3 text-white
                                ${index === 3 && 'border-2 px-5 border-[#FF9800] text-[#FF9800]'}
                                `}>
                                <a href={data.link}>
                                    {data.nav}
                                </a>
                            </motion.div>
                        )
                    })}
                </div>

                {/* Mobile Responsive */}
                <div className="px-5 flex items-center lg:hidden">
                    <button onClick={() => setIsOpen(!isOpen)} className={` inline-flex items-center justify-center p-2 rounded-md text-white focus:outline-none`}>
                        {isOpen ?
                            <svg
                                initial={{ scale: 0 }}
                                whileInView={{ scale: 1 }}
                                whileTap={{ scale: 0 }}
                                transform={{ duration: 0.5 }}
                                className="h-6 w-6 block text-white "
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                            :
                            <motion.svg
                                initial={{ scale: 0 }}
                                whileInView={{ scale: 1 }}
                                whileTap={{ scale: 0 }}
                                transform={{ duration: 0.5 }}
                                className="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                            </motion.svg>}
                    </button>
                    <motion.div
                        initial={{ x: 300 }}
                        whileInView={{ x: 100 }}
                        transition={{ duration: 0.3 }}
                        className={isOpen ? "showMenuNav my-[15vh] h-screen w-screen bg-neutral-100 rounded-xl" : "hideMenuNav"}>
                        <div
                            className={`${isOpen ? 'block ' : 'hidden'
                                } lg:hidden transition-all w-full px-2`}
                            id="mobile-Homeu"
                            ref={menuRef}
                        >
                            <motion.div
                                variants={{
                                    open: {
                                        clipPath: "inset(0% 0% 0% 0% round 10px)",
                                        transition: {
                                            type: "spring",
                                            bounce: 0,
                                            duration: 0.7,
                                            delayChildren: 0.3,
                                            staggerChildren: 0.05
                                        }
                                    },
                                }}
                                style={{ pointerEvents: isOpen ? "auto" : "none" }}
                                className="py-[5vh] pb-3 space-y-[10vh] h-screen sm:px-3 transition-all ease-in-out duration-1000" ref={menuRef}>

                                {navbar.map((data, index) => {
                                    return (
                                        <motion.a key={data.id}
                                            href={data.link}
                                            onClick={() => setIsOpen(false)}
                                            ref={menuRef}
                                            initial={{ x: 100, opacity: 0, scale: 0 }}
                                            whileInView={{ y: 0, x: 0, opacity: 1, scale: 1 }}
                                            transition={{ duration: 0.2, delay: 0.1 * index, easi: [0.33, 1, 0.68, 1] }}
                                            className={` text-neutral-950 hover:text-[#FF9800] text-left hover:tracking-widest scroll-smooth hover:scroll-auto transition-all block px-3 p-2 w-fit font-[lightpoppins]
                                            ${index === 3 && 'border-2 px-5 border-[#FF9800] text-[#FF9800]'}
                                            `}
                                        >
                                            {data.nav}
                                        </motion.a>
                                    )
                                })}
                            </motion.div>
                        </div>
                    </motion.div>
                </div>

            </nav>
            <style>{`
      .hideMenuNav {
        display: none;
      }
      .showMenuNav {
        display: block;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 10;
        display: flex;
        flex-direction: column;
        // justify-content: space-evenly;
        align-items: center;
      }
    `}</style>

            {/* Mobile Homeu, toggle classes based on Homeu state */}
            {/* <motion.div
                className="w-[75%] mx-auto fixed h-1 bg-[#fff4d6] progress-bar rounded-full"
                style={{ scaleX: scrollYProgress }}
            /> */}

        </div>
    )
}

export default Navbar
