import React, { useState } from 'react'
import Contact from './Contact';
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { motion } from "framer-motion";
import { MaskText } from '../components/MaskText';

function GetInTouch() {

    const [userData, setuserData] = useState({
        name: "",
        represent: "",
        phone: "",
        email: "",
        message: ""
    });

    let name, value;
    const postUserData = (event) => {
        name = event.target.name;
        value = event.target.value;

        setuserData({ ...userData, [name]: value })
    }

    // connect with Database 
    const submitData = async (event) => {
        event.preventDefault();
        const { name, represent, phone, email, message } = userData;

        if (name && represent && phone && email && message) {
            const res = await fetch("https://sitescript-db364-default-rtdb.firebaseio.com/userDataRecords.json",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        name, represent, phone, email, message
                    }),
                }
            );

            if (res) {
                setuserData({
                    name: "",
                    represent: "",
                    phone: "",
                    email: "",
                    message: "",
                })
                // alert("Data Stored");
                // toast("Wow so easy !");
                toast.success('Done! Our team will contact you soon.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                // toast.success("Our team will contact you soon");

            }
            else {
                toast.error('Invalid Credentials', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
        else {
            // alert("Unfilled");
            toast.error('Invalid Credentials', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };


    return (
        <div class="w-full mx-auto py-20 bg-transparent" id='contact'>
            <div class="flex flex-col text-center w-full">
                <h1
                    class="w-[100%] font-[poppins] font-semibold uppercase text-neutral-950 lg:text-[10vw] text-[10vw]"> Get In Touch</h1 >

            </div>
            <div
                data-scroll data-scroll-speed='0.2'
                class="grid grid-cols-1 md:grid-cols-12 border border-neutral-800 backdrop-blur-xl bg-white [background:radial-gradient(50%_50%_at_50%_50%,#404040,#0a0a0a)]">
                <div class=" md:col-span-4 py-10 px-10 text-white">
                    <p class="text-[#FF9800] mt-4 xl:text-3xl text-xl lg:mx-0 mx-auto w-fit leading-7 font-[poppins] font-semibold">
                        Social Links
                    </p>
                    <div className='my-10 w-fit lg:mx-0 mx-auto'>
                        <Contact />
                    </div>

                    <div class="flex items-center mt-5 font-[poppins] font-semibold">

                        <a href='mailto: info@sitescript.in' class="text-base flex items-center tracking-wider lg:mx-0 mx-auto w-fit "><AiOutlineMail className='items-center' />&nbsp; info@sitescript.in</a>
                    </div>
                    <div class=" items-center mt-5 space-y-5 font-[poppins] font-semibold tracking-wider">

                        <a href='tel:+919599791185' class="text-base items-center flex mx-auto w-fit lg:mx-0 "><AiOutlinePhone className='items-center' />&nbsp;+91 9599791185</a>
                    </div>
                </div>

                <form method='POST' class="md:col-span-8 p-10">
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-[#FF9800] lg:text-[1.5vw] font-bold font-[poppins] mb-2"
                                for="grid-first-name">
                                <MaskText text='~ Hi ! My name is' />
                            </label>
                            <input
                                class="appearance-none block w-full bg-transparent text-white border border-neutral-800 rounded py-3 px-4 leading-tight focus:outline-none  focus:border-neutral-800 font-[poppins] font-semibold"
                                id="grid-first-name" type="text" name='name' placeholder="Your name..." value={userData.name} onChange={postUserData} />
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                            <label class="block uppercase tracking-wide text-[#FF9800] lg:text-[1.5vw] font-bold font-[poppins] mb-2"
                                for="grid-last-name">
                                <MaskText text='~ Represent ' />
                                {/* <span className='text-[1.2vw]'>~ Agencies are invited to collaborate</span> */}
                            </label>
                            <input
                                class="appearance-none block w-full bg-transparent text-white border border-neutral-800 rounded py-3 px-4 leading-tight focus:outline-none  focus:border-neutral-800 font-[poppins] font-semibold"
                                id="grid-last-name" type="text" name='represent' placeholder="An Individual or A Company" value={userData.represent} onChange={postUserData} />
                        </div>
                    </div>
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-[#FF9800] lg:text-[1.5vw] font-bold font-[poppins] mb-2"
                                for="grid-first-name">
                                <MaskText text='~ Your Phone' />
                            </label>
                            <input
                                class="appearance-none block w-full bg-transparent text-white border border-neutral-800 rounded py-3 px-4 leading-tight focus:outline-none  focus:border-neutral-800 font-[poppins] font-semibold"
                                id="grid-first-name" type="number" name='phone' placeholder="+91 XXXXXXXXXX" value={userData.phone} onChange={postUserData} />
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                            <label class="block uppercase tracking-wide text-[#FF9800] lg:text-[1.5vw] font-bold font-[poppins] mb-2"
                                for="grid-last-name">
                                <MaskText text='~ Your E-mail' />
                            </label>
                            <input
                                class="appearance-none block w-full bg-transparent text-white border border-neutral-800 rounded py-3 px-4 leading-tight focus:outline-none  focus:border-neutral-800 font-[poppins] font-semibold"
                                id="grid-last-name" type="email" name='email' placeholder="example123@gmail.com" value={userData.email} onChange={postUserData} />
                        </div>
                    </div>


                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                            <label class="block uppercase tracking-wide text-[#FF9800] lg:text-[1.5vw] mb-[1.5vw] font-bold font-[poppins]"
                                for="grid-password">
                                <MaskText text='~ Your Message' />
                            </label>
                            <textarea type='text' name='message' placeholder="" value={userData.message} onChange={postUserData} rows="10"
                                class="appearance-none block w-full bg-transparent text-white border border-neutral-800 rounded py-3 px-4 leading-tight focus:outline-none  focus:border-neutral-800 font-[poppins] font-semibold"></textarea>
                        </div>
                        <div class="mx-auto justify-between w-fit px-3">
                            <motion.button
                                whileTap={{
                                    scale: 0.8,
                                    borderRadius: "100%",
                                }}
                                data-te-ripple-init
                                data-te-ripple-color="light"
                                class="text-neutral-950 hover:text-[#FF9800] backdrop-blur-lg bg-white rounded-md py-2 px-6 shadow hover:shadow-neutral-800 duration-700 lg:text-[1.2vw] text-[5vw] font-[poppins] my-[5vh]"
                                type='submit' onClick={submitData}>
                                Send Message
                            </motion.button>
                        </div>

                    </div>

                </form>

            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
        // <!-- Container for demo purpose -->
    )
}

export default GetInTouch;


