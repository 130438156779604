import React from 'react'
import Banner from '../landingPage/Banner'
import Body from '../landingPage/Body'
import Testimonials from '../landingPage/Testimonials'
import QnA from '../landingPage/QnA'
import GetInTouch from '../landingPage/GetInTouch'
import Footer from '../components/Footer'
import Layout from '../components/Layout/Layout'

const Home = () => {
    return (
        <Layout title={'siteScript-Home'}>
            <div className='bg-fixed overflow-hidden' id=''
            >
                <Banner />
                {/* <About /> */}
                <Body />
                <div className='w-full border border-neutral-800'></div>

                <Testimonials />
                <div className='w-full border border-neutral-800'></div>

                <QnA />
                <div className='w-full border border-neutral-800'></div>

                <GetInTouch />

                {/* <Footer /> */}
            </div>
        </Layout>
    )
}

export default Home
