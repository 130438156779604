import React from 'react'
import ReadMoreButton from '../components/ReadMoreButton'
import Navbar from '../landingPage/Navbar'
// import { Example } from '../components/MouseImageTrail'
import { motion } from 'framer-motion'
import { MaskText } from '../components/MaskText'
import Layout from '../components/Layout/Layout'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
// import ReadMoreButton from './ReadMoreButton';

function About() {

    const team = [

        {
            'img': 'team/masab.jpg',
            'role': 'UIUX & Graphic Designer',
            'name': 'Masab Furkan'
        },
        {
            'img': 'team/dhruvgarg.jpg',
            'role': 'Graphic & Motion Designer',
            'name': 'Dhruv Garg'
        },
        {
            'img': 'team/Deepraj.jpg',
            'role': 'Website Developer',
            'name': 'Deepraj'
        },
        {
            'img': 'team/final.jpg',
            'role': 'Website Developer',
            'name': 'Kshitij Jain'
        },


    ]
    const who = [
        'designer',
        'developer',
        'editor'
    ]
    return (
        <Layout title={'siteScript-About Us'}>
            <ParallaxProvider className='mx-auto w-fit overflow-hidden' id='container'>
                <div className='relative lg:bg-contain bg-black [background:radial-gradient(50%_50%_at_50%_50%,#404040,#0a0a0a)] overflow-hidden' id=''

                >

                    {/* <p className="flex items-center gap-2 text-3xl  uppercase text-neutral-800"> */}
                    {/* <FiMousePointer /> */}
                    <img className='absolute lg:w-[60%] w-[100%] lg:top-0 top-[45%] lg:left-[50%] opacity-30' src='images/7.png' />
                    <Parallax 
                                translateY={['-80', '100']}

                    // data-scroll data-scroll-speed='0.5'
                        className='text-white h-screen lg:py-[10vh] py-[20vh] backdrop-blur-sm'>

                        {/* <Navbar /> */}

                        <motion.div
                            initial={{ x: -100, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            transition={{ duration: 0.5 }}
                            viewport={{ once: true }}

                            className='overflow-hidden'
                        >
                            <Parallax
                                // opacity={['1.5', '0']}
                                // scale={['1.2', '0.7']}
                                className='lg:w lg:text-[4vw] text-[7vw] font-[poppins]  lg:text-start text-justify lg:w-full w-[90%] mx-auto space-y-5 lg:mt-[10vh] lg:px-[5vw] drop-shadow-[0px_0px_2px_var(--tw-shadow-color)] shadow-neutral-100'>

                                <span className='font-[poppins] text-[#FF9800]'>* </span>
                                We are
                                a dynamic and passionate team of creative professionals dedicated to helping brands and businesses thrive in the digital landscape.
                            </Parallax>
                        </motion.div>

                    </Parallax>
                    {/* </p> */}
                </div>



                <div className='lg:py-[12vh] py-[24vh]  font-[poppins] px-[5vw] '>
                    <h2 className='text-neutral-800 drop-shadow-[5px_5px_5px_var(--tw-shadow-color)] shadow-neutral-800 lg:text-[2vw]'
                    // data-scroll data-scroll-speed='0.1'
                    >
                        <MaskText text='Team' />
                    </h2>


                    <motion.div
                        initial={{ opacity: 0, y: 100 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.3, duration: 0.3 }}
                        viewport={{ once: true }}
                        class="relative lg:w-[%] overflow-hidden rounded-xl"
                    >
                        <Parallax
                            // scale={['1.5', '0']}
                            // opacity={['1.5', '0']}
                            class="items-center justify-center text-neutral-800"
                        >

                            <img className='rounded-full lg:w-[25vw] w-[50vw] lg:h-[25vw] mx-auto' src='team/namanbansalprofile.jpg' alt='founder' />

                            <div className='my-[5vh] space-y-[2vh] font-normal font-[poppins]'>
                                <h2 className='w-fit mx-auto lg:text-[2vw] text-[6vw] tracking-wide text-center text-neutral-800'>Naman Bansal</h2>
                                <h2 className='w-fit mx-auto text-center text-neutral-800 italic drop-shadow-[5px_5px_5px_var(--tw-shadow-color)] shadow-[#FF9800]'>
                                    <span className=''>~&nbsp;</span>
                                    Website & Brand Strategist
                                </h2>
                            </div>
                        </Parallax>
                    </motion.div>


                    <div className='py-[5vh] grid lg:grid-cols-2 space-x-[5vw] mx-auto'>
                        {team.map((data, index) => {
                            return (
                                <motion.div
                                    initial={{ opacity: 0, y: 100 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.3 * index, duration: 0.3 }}
                                    viewport={{ once: true }}
                                    class="relative lg:w-[%] overflow-hidden rounded-xl"
                                >
                                    <Parallax
                                        // scale={['1.5', '0']}
                                        // opacity={['1.5', '0']}
                                        class="items-center justify-center text-neutral-800"
                                    >
                                        <img className='rounded-full lg:w-[25vw] w-[50vw] lg:h-[25vw] mx-auto' src={data.img} alt='founder' />

                                        <div className='my-[5vh] space-y-[2vh] font-normal font-[poppins]'>
                                            <h2 className='w-fit mx-auto lg:text-[2vw] text-[6vw] tracking-wide text-center text-neutral-800'>{data.name}</h2>
                                            <h2 className='w-fit mx-auto text-center text-neutral-800 italic drop-shadow-[5px_5px_5px_var(--tw-shadow-color)] shadow-[#FF9800]'>
                                                <span className=''>~&nbsp;</span>
                                                {data.role}
                                            </h2>
                                        </div>
                                    </Parallax>
                                </motion.div>
                            )
                        })}
                    </div>
                </div>
            </ParallaxProvider>
        </Layout>
    )
}

export default About
