import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { MaskText } from '../components/MaskText'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'
import { motion } from 'framer-motion';
import Stats from '../components/Stats';

const Testimonials = () => {

    const settings = {
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const client = [
        {
            'title': 'Website Design',
            'desc': 'From the initial concept to the final product, siteScript exceeded our expectations every step of the way.',
            'img': '',
            'name': 'Srijan Mehrotra',
            'position': 'Founder - Model Verse'
        },
        {
            'title': 'Website Design',
            'desc': 'SiteScript. They have a very good experience in creating website, I have hired them for my website development and I get a good result.',
            'img': '',
            'name': 'Rajan Kumar',
            'position': 'Founder - Staffison'
        },
        {
            'title': 'Efficient customer support',
            'desc': 'Choosing siteScript for our website design and development was one of the best decisions we made. Their team was responsive, collaborative, and highly skilled.',
            'img': '',
            'name': 'Nikhil Bafna',
            'position': 'Founder - Tipriyo'
        },
        {
            'title': 'Efficient customer support',
            'desc': 'He was recommended to me by a colleague for site development and design, and I can assure you that working with him would be beneficial. He is really imaginative and attentive to your needs, and suddenly presto—the job is done. He continued to communicate even after the project was turned over to see if anything needed to be changed and whether everything was going well. I endorse him. Bravo to you, friend 👍',
            // 'img': '',
            'name': 'Ravi',
            'position': 'Prime Care Alliance'
        },
        {
            'title': 'Efficient customer support',
            'desc': 'As a startup, we needed a website that would impress investors and attract customers. siteScript not only met but exceeded our expectations.',
            // 'img': '',
            'name': 'Ravi',
            'position': 'Unity Health System'
        },
    ]
    return (
        <ParallaxProvider>

            <div className='bgneutral-950 lg:py-[12vh]  font-[poppins] font-semibold px-[5vw]'>
                <h2 className='text-neutral-800 lg:text-[3vw] uppercase'
                // data-scroll data-scroll-speed='0.1'
                >
                    <MaskText text='Our Clients' />
                </h2>

                <div>
                    <div className=""
                        data-scroll data-scroll-speed='0.2'>
                        <section className="mx-auto w-full p-[] overflow-hidden">
                            <div className="flex items-center justify-center flex-col gap-y-2 py-5"
                            // data-scroll data-scroll-speed='0.5'
                            >
                                <h2 className="w-full text-[6vw] lg:text-[8vh] mx-auto text-center text-neutral-950">
                                    <Parallax>
                                        Here's what our
                                        <span className="text-[#FF9800] drop-shadow-[5px_5px_5px_var(--tw-shadow-color)] shadow-[#FF9800]">&nbsp;customers</span> have to say
                                    </Parallax>
                                </h2>
                                <p className="lg:text-[4vh] text-[2vh] font-medium text-neutral-500">Discover how our service can benefit you</p>
                            </div>


                            <div className='lg:w-[100%]'
                            >
                                <Slider {...settings}>
                                    {client.map((testimonial) => (


                                        <div className=" h-full">
                                            <div className="rounded-xl bg-white [background:radial-gradient(50%_50%_at_50%_50%,#404040,#0a0a0a)] mx-[1vw]">
                                                <div className="md:h-[40vh] h-[40vh] flex-auto p-4 md:p-6">
                                                    <p className="lg:text-[1.2vw] text-[4vw] text-justify text-white">
                                                        " {testimonial.desc} "
                                                    </p>
                                                </div>
                                                <div className="p-4  rounded-b-xl md:px-7 ">
                                                    <div className="flex items-center">
                                                        {/* <div className="flex-shrink-0"> */}
                                                        {/* <img className="size-8 sm:h-[2.875rem] sm:w-[2.875rem] rounded-full" src="https://images.unsplash.com/photo-1579017331263-ef82f0bbc748?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=900&h=900&q=80" alt="testimonials Description" /> */}
                                                        {/* </div> */}
                                                        <div className="grow ms-3">
                                                            <p className="text-sm sm:text-base text-white">
                                                                {testimonial.name}                                                                            </p>
                                                            <p className="text-xs text-[#FF9800]">
                                                                {testimonial.position}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                    ))}
                                </Slider>
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1 }}
                                    transition={{ delay: 0.5, duration: 0.5 }}
                                    className="grid gap-6 grid-cols-1 sm:gap-12 lg:grid-cols-3 lg:gap-8 text-center">
                                    {/* Stats */}
                                    <div>
                                        {/* <h4 className="text-lg sm:text-xl font-semibold text-white">Accuracy rate</h4> */}
                                        <p className="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-[#FF9800]">100%</p>
                                        <p className="mt-1 text-neutral-800">Accuracy rate fulfilled projects</p>
                                    </div>
                                    {/* End Stats */}
                                    {/* Stats */}
                                    <div>
                                        {/* <h4 className="text-lg sm:text-xl font-semibold text-white">Startup businesses</h4> */}
                                        <p className="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-[#FF9800]">20+</p>
                                        <p className="mt-1 text-neutral-800">Startup businesses partner with SiteScript</p>
                                    </div>
                                    {/* End Stats */}
                                    {/* Stats */}
                                    <div>
                                        <p className="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-[#FF9800]">85%</p>
                                        {/* <h4 className="text-lg sm:text-xl text-neutral-800">Happy customer</h4> */}
                                        <p className="mt-1 text-neutral-800">Happy customer this year alone</p>
                                    </div>
                                    {/* End Stats */}
                                </motion.div>
                            </div>

                        </section>
                    </div>
                </div>
            </div>


        </ParallaxProvider>
    )
}

export default Testimonials
