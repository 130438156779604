import React from 'react'
import { Helmet } from "react-helmet"
import Navbar from '../../landingPage/Navbar'
import Footer from '../Footer'

const Layout = ({ title, children }) => {
    return (
        <div className='w-[100%] mx-auto'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <meta name="description" content='siteScript' />
            </Helmet>

            <div className=''>
                <div className=' lg:w-full w-[100%] mx-auto bg-nw absolute text-white text-center z-10'>
                    <Navbar className='scroll-smooth z-1 relative' />
                </div>

                <main>
                    {children}
                </main>
                <Footer />
            </div>
        </div>
    )
}
Layout.defaultProps = {
    title: 'siteScript',
    desc: 'siteScript'
}
export default Layout
