import React from 'react';
import { motion } from "framer-motion";
import { BsFacebook, BsInstagram, BsLinkedin, BsTwitterX } from 'react-icons/bs';


const Contact = () => {

    const socials = [
        {
            "links": <BsInstagram />,
            "bgcolor2": "#fff4d6",
            "color": "black",
            "link": "https://www.instagram.com/_sitescript_/"
        },
        {
            "links": <BsLinkedin />,
            "bgcolor1": "#0A66C2",
            "bgcolor2": "#0077B5",
            "color": "white",
            "link": "https://www.linkedin.com/company/101243745/admin/feed/posts/"
        },
        {
            "links": <BsTwitterX />,
            "bgcolor1": "#2b3137",
            "bgcolor2": "#373535",
            "color": "white",
            "link": "https://twitter.com/_siteScript_"
        },
        {
            "links": <BsFacebook />,
            "bgcolor1": "#2b3137",
            "bgcolor2": "#373535",
            "color": "white",
            "link": "https://www.facebook.com/people/siteScript/61553868025255/"
        },
    ]

    return (
        <div>
            {socials.map(data => {
                return (


                    <motion.a
                        href={data.link}
                        whileHover={{ scale: 1.2, rotate: 360 }}
                        whileTap={{
                            scale: 0.8,
                            rotate: -90,
                            borderRadius: "100%",
                        }}
                        type="button"
                        data-te-ripple-init
                        data-te-ripple-color="light"
                        class="mb-2 inline-block rounded-full p-3 mx-1 text-lg leading-normal shadow-md ease-in-out duration-100 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg bg-white text-neutral-700"
                    >
                        {data.links}
                    </motion.a>
                )
            })}
        </div>
    );
};

export default Contact;