import React, { useState } from 'react'
import Layout from '../components/Layout/Layout'
import { TextParallaxContentExample } from '../components/ParallaxAnimation'
import { FiArrowUpRight } from "react-icons/fi";
import { motion } from 'framer-motion'
import WebPortfolio from '../components/Porfolio/WebPortfolio';
import LogoPortfolio from '../components/Porfolio/LogoPortfolio';
import BrandPostPortfolio from '../components/Porfolio/BrandPostPortfolio';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

const Portfolio = () => {
    const sections = {
        "Website Design & Development": {
            work: [

                {
                    'page': <WebPortfolio />
                }
            ],
        },
        "Logo Designing & Brand Identity": {
            work: [
                {
                    'page': <LogoPortfolio />
                }
            ],
        },
        "Brand Post": {
            work: [
                {
                    'page': <BrandPostPortfolio />
                }
            ],
        },

    };

    const [activeSection, setActiveSection] = useState("Website Design & Development");

    return (
        <Layout title={'siteScript-Portfolio'}>
            <ParallaxProvider>
                <div className='overflow-hidden relative mx-auto w-full font-[lightpoppins] bg-black [background:radial-gradient(50%_50%_at_50%_50%,#d4d4d4,#0a0a0a)]' id=''>
                    <div className='w-full h-full lg:py-[20vh] py-[20vh]' style={{
                        // backgroundImage: `url(cornered-stairs.svg)`,
                        // backgroundColor: "#B99470"
                    }}
                    >

                        <div className='flex relative'>

                            <h2 className='lg:text-[8vw] text-[7vw] font-[lightpoppins] text-white lg:text-start text-center space-y-5 lg:mt-[20vh]  px-[5vw]'>We Done...</h2>
                            <Parallax
                                className='-z-1 absolute lg:w-[60%] w-[100%] left-[50%] lg:top-[0%] top-[45%]'
                                translateX={['0', '-100%']}
                                translateY={['20', '-100%']}
                                scale={['0', '3']}
                            >
                                {/* <img className='opacity-' src='/images/Saly-6.webp' alt='Images'/> */}
                            </Parallax>

                        </div>

                    </div>

                    <div className="">
                        <div className="mb-8">
                            {Object.keys(sections).map(section => (
                                <button
                                    key={section}
                                    className={`px-4 py-2 m-2 rounded ${activeSection === section ? 'bg-[#FF9800] text-neutral-800' : 'bg-neutral-950 text-[#FF9800] border border-[#FF9800]'
                                        }`}
                                    onClick={() => setActiveSection(section)}
                                >
                                    {section}
                                </button>
                            ))}
                        </div>
                        <div className="text-neutral-900">
                            <div className="w-full h-full">
                                {sections[activeSection].work.map((work, index) => (
                                    <div>
                                        {work.page}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </ParallaxProvider>
        </Layout >
    )
}

export default Portfolio
