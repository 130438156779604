'use client';
import { useEffect, useRef, useState } from 'react';
import styles from './page.module.css'
// import Image from 'next/image';
import Lenis from '@studio-freight/lenis'
import { useTransform, useScroll, motion } from 'framer-motion';
import { MaskText } from './MaskText';

const images = [
    { 'img': '/features/asp.png', 'title': 'ASP.NET' },
    { 'img': '/features/js.png', 'title': 'JavaScript' },
    { 'img': '/features/reactjs.png', 'title': 'React JS' },
    { 'img': '/features/nextjs.png', 'title': 'Next JS' },
    { 'img': '/features/mongodb.png', 'title': 'MongoDB' },
    { 'img': '/features/nodejs.png', 'title': 'Node JS' },
    { 'img': '/features/django.png', 'title': 'Django' },
    { 'img': '/features/auth0.png', 'title': 'Auth0' },
    { 'img': '/features/firebase.png', 'title': 'Firebase' },
    { 'img': '/features/wordpress.png', 'title': 'Wordpress' },
    { 'img': '/features/shopify.png', 'title': 'Shopify' },
    { 'img': '/features/sanity.png', 'title': 'Sanity' },
    { 'img': '/features/bootstrap.png', 'title': 'Bootstrap' },
    { 'img': '/features/tailwind.png', 'title': 'Tailwind CSS' },
]

export default function ServiceComponent() {

    const gallery = useRef(null);
    const [dimension, setDimension] = useState({ width: 0, height: 0 });

    const { scrollYProgress } = useScroll({
        target: gallery,
        offset: ['start end', 'end start']
    })
    const { height } = dimension;
    const y = useTransform(scrollYProgress, [0, 1], [0, height * 2])
    const y2 = useTransform(scrollYProgress, [0, 1], [0, height * 3.3])
    const y3 = useTransform(scrollYProgress, [0, 1], [0, height * 1.25])
    const y4 = useTransform(scrollYProgress, [0, 1], [0, height * 3])

    useEffect(() => {
        const lenis = new Lenis()

        const raf = (time) => {
            lenis.raf(time)
            requestAnimationFrame(raf)
        }

        const resize = () => {
            setDimension({ width: window.innerWidth, height: window.innerHeight })
        }

        window.addEventListener("resize", resize)
        requestAnimationFrame(raf);
        resize();

        return () => {
            window.removeEventListener("resize", resize);
        }
    }, [])

    return (
        <main className={`${styles.main} bg- h-[]`}>
            {/* ksjdfvjdsb */}
            <div className={styles.spacer}>
            </div>
            <motion.div
                initial={{ x: -100, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <div className='bgneutral-950 pt-[24vh] font-bold font-[astro] px-[5vw] '>
                    <h2 className='text-white lg:text-[2vw] '
                        data-scroll data-scroll-speed='0.1'
                    >
                        <MaskText text='Tools...' />
                    </h2>
                </div>
            </motion.div>
            <div ref={gallery} className={styles.gallery}>

                <Column images={[images[0], images[1], images[2], images[3], images[4], images[5]]} y={y} />
                <Column images={[images[6], images[7], images[8], images[9], images[10], images[11]]} y={y2} />
                <Column images={[images[12], images[13], images[2], images[5], images[8], images[1]]} y={y3} />
                <Column images={[images[9], images[1], images[2], images[6], images[7], images[8]]} y={y4} />
            </div>
            <div className={styles.spacer}></div>
        </main>
    )
}

const Column = ({ images, y }) => {
    return (
        <motion.div
            className={styles.column}
            style={{ y }}
        >
            {
                images.map((src, i) => {
                    return <div key={i} className='w-[10vw]'>
                        <img
                            src={src.img}
                            alt='image'
                            fill
                        />
                        <h2 className='text-center'>{src.title}</h2>
                    </div>
                })
            }
        </motion.div>
    )
}