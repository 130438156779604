import React from 'react'
import { motion } from 'framer-motion';
import { BsArrowRight } from "react-icons/bs";
import { MaskText } from '../components/MaskText'
import { Link } from 'react-router-dom';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'


const Body = () => {

    const speciality = [
        {
            'spec': 'Web Development',
            'img': 'images/web.webp',
        },
        {
            'spec': 'AI Tech',
            'img': 'images/Saly-39.png',
        },
        {
            'spec': 'Graphic Designing',
            'img': 'images/Saly-13.png',
        },
        // {
        //     'spec': 'Social Media Marketing',
        //     'img': 'images/Saly-12.webp',
        // },
        {
            'spec': 'Video Editing',
            'img': 'images/Saly-24.png',
        },
    ]

    const startegies = [
        {
            'img': 'images/brandingicon.webp',
            'title': 'Branding & Digital Strategies',
            // 'desc': ''
        },
        {
            'img': 'images/webicon.webp',
            'title': 'Website Design & Development',
            // 'desc': ''
        },
        {
            'img': 'images/performacefrivenicon.webp',
            'title': 'Performance Driven Marketing',
            // 'desc': ''
        },
        {
            'img': 'images/successicon.webp',
            'title': 'Enjoy Result',
            // 'desc': ''
        },
    ]

    return (
        <ParallaxProvider>



            <div className='w-full border border-neutral-800'></div>

            {/* Brand Development Path */}
            {/* Icon Blocks */}
            <div className="py-[12vh] font-[lightpoppins] px-[5vw] ">

                <motion.h2
                    initial={{ y: 100, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.1, duration: 0.5 }}
                    viewport={{ once: true }}
                    className='lg:w-[90%] mx-auto text-neutral-800 lg:text-[5vw] text-[6vw] font-[poppins] text-center p-[5vw] capitalize overflow-hidden'
                    data-scroll data-scroll-speed='0.1'
                >
                    <Parallax
                    // scale={['1.5', '0.7']}
                    // 
                    >
                        Take your <span className='drop-shadow-[5px_5px_5px_var(--tw-shadow-color)] shadow-[#FF9800] text-[#FF9800] font-semibold'>business</span> to the next level with SiteScript
                    </Parallax>
                </motion.h2>

                <Parallax

                >
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 items-center gap-12 overflow-hidden"
                        data-scroll data-scroll-speed='0.1'
                    >

                        {/* Icon Block */}
                        {startegies.map((data, index) => {
                            return (
                                <motion.div
                                    initial={{ y: 100, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ delay: 0.3 * index, duration: 0.5 }}
                                    viewport={{ once: true }}
                                    // translateY={['50', '-50']}
                                    // translateX={['-50', '100']}
                                    className='w-[100%] h-[100%] bg-neutral-800 rounded-xl shadow-md shadow-white text-center items-center mx-auto py-[2vw]'>
                                    <motion.div
                                        className='bg-white rounded-full p-[1.5vh] lg:w-[5vw] w-[15vw] mx-auto'>
                                        <img src={data.img} alt={data.title} />
                                    </motion.div>
                                    <div className="bg-gradient-to-r from-gray-200 via-gray-50 to-transparent w-fit mx-auto h-0.5 mt-6 ">
                                        <div className="bg-gray-400 w-9 h-0.5 dark:bg-[#FF9800] mx-auto" />
                                    </div>
                                    <div className="mt-5">
                                        <h3 className="text-lg  text-white">
                                            <MaskText text={data.title} />
                                        </h3>
                                        {/* <p className="mt-1 text-gray-600 dark:text-white">Responsive, and mobile-first project on the web</p> */}
                                    </div>
                                </motion.div>
                            )
                        })}
                        {/* End Icon Block */}
                        {/* Icon Block */}
                    </div>
                </Parallax>
            </div>
            {/* End Icon Blocks */}


            <div className='relative py-[12vh] bg-neutral-950 border-t border-neutral-800'>
                <Parallax
                    className='absolute lg:w-[60%] w-[100%] left-[50%] lg:top-[0%] top-[10%]'
                    translateX={['0', '-100%']}
                // translateY={['0', '-100%']}
                >
                    <img className=' opacity-60' src='images/video.webp' alt='Images'
                    // data-scroll data-scroll-speed='1'
                    />
                </Parallax>
                <div className='text-white lg:px-0 px-[5vw] lg:w-[80vw] w-full mx-auto lg:leading-tight'>
                    <div
                        // scale={['1.1', '0.5']}
                        //  
                        // translateY={['50', '-50']}
                        // translateX={['-50', '50']}
                        className=' capitalize lg:text-[10vh] text-[5vh] font-[poppins] drop-shadow-[1px_1px_5px_var(--tw-shadow-color)] shadow-[#FF9800]'
                    // data-scroll data-scroll-speed='0.5'
                    >
                        <span className='text-[#FF9800] '>*</span>
                        <MaskText text='Breathing life into Ideas, Crafting' />
                        <MaskText text='and Creating with Excellence.' />
                        <MaskText text='Your gateway to seamless solutions.' />
                        {/* <MaskText text='solutions.' /> */}
                    </div>
                </div>
            </div>


            <div className='w-full border border-neutral-800'></div>



            {/* Our Speciality */}
            <div className='py-[12vh]  font-[poppins] px-[5vw] uppercase'>
                <h2 className='text-neutral-800 lg:text-[3vw] '
                    data-scroll data-scroll-speed='0.1'
                >
                    <MaskText text='Our Speciality' />
                </h2>
                <div className='grid md:grid-cols-2 group space-y-[] overflow-hidden'
                // data-scroll data-scroll-speed='0.2'
                // style={{backgroundImage : 'url(images/logo.png)'}}
                >
                    {speciality.map((data, index) => (
                        <a href='/contact' className='group bg-cover'
                        // scale={['1.5', '0.5']}
                        >
                            <motion.div
                                initial={{ x: -100, opacity: 0 }}
                                whileInView={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.4, delay: 0.2 * index }}
                                viewport={{ once: true }}
                                className='md:w-[40vw] md:h-[50vh] mx-auto m-10 lg:text-[4vw] text-[6vw] items-center font-[lightpoppins] p-[5vh] group hover:bg- transition-all ease-linear duration-400 hover:text-white backdrop-blur-sm bg-neutral0 text-neutral-800 border-2 border-neutral-800 hover:tracking-
                        
                            group-hover:scale-75 hover:!scale-100 hover:shadow-lg md:flex relative overflow-hidden

                        before:absolute before:top-0 before:-left-full before:w-full before:h-full before:bg-neutral-900 before:transition-all before:duration-500 before:ease-in-out before:z-[-1] hover:before:left-0  group
                        '>

                                <h2>{data.spec}</h2>

                                <img className='w-[30vh] scale-0 lg:group-hover:scale-150 group-hover:scale-100 justify-end ml-auto'
                                    src={data.img}
                                    alt='Speciality' />

                                {/* <Link to='/contact' className='flex items-center justify-end lg:text-[2vw] text-[6vw] text-[#FF9800]  ml-auto scale-0 group-hover:scale-100 cursor-pointer'>
                                    Get Now<BsArrowRight />
                                </Link> */}

                            </motion.div>
                        </a>
                    ))}

                </div>
            </div>


            <div className='w-full border border-neutral-800'></div>



            <div className='lg:px-[5vw] tracking-wider font-[poppins] lg:flex lg:py-[24vh] py-[12vh] relative'>

                <Parallax
                    className='-z-1 absolute lg:w-[60%] w-[100%] left-[50%] lg:top-[50%] top-[45%]'
                    translateX={['0', '-100%']}
                    translateY={['20', '-100%']}
                    scale={['0', '3']}
                >
                    <img className='opacity-' src='images/Saly-43.png' alt='Images'
                    // data-scroll data-scroll-speed='1'
                    />
                </Parallax>
                <h2 className='lg:text-[5vw] text-[10vw] border-dashed border-l-4 border-t-4 border-b-4 border-neutral-800 text-neutral-950 px-[2.5vw] py-[12vh] leading-10 mx-auto w-[90%] lg:w-[50%] drop-shadow-[5px_5px_5px_var(--tw-shadow-color)] shadow-neutral-500'>
                    <Parallax

                    // initial={{ x: -100, opacity: 0 }}
                    // whileInView={{ x: 0, opacity: 1 }}
                    // transition={{ duration: 0.5, delay: 0.1 }}
                    // viewport={{ once: true }}
                    >
                        Full service
                    </Parallax>
                    <br />
                    <Parallax

                        // translateX={['0', '50']}
                        initial={{ x: -100, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        viewport={{ once: true }}
                    >
                        place for both
                    </Parallax>
                    <br />
                    <Parallax

                        // translateX={['0', '50']}
                        initial={{ x: -100, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.5, delay: 0.3 }}
                        viewport={{ once: true }}
                    >
                        startups and
                    </Parallax>
                    <br />
                    <Parallax

                        // translateX={['0', '50']}
                        initial={{ x: -100, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                        viewport={{ once: true }}
                    >
                        established
                    </Parallax>
                    <br />
                    <Parallax

                        // translateX={['0', '50']}
                        initial={{ x: -100, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.5, delay: 0.5 }}
                        viewport={{ once: true }}
                    >
                        businesses
                    </Parallax>
                </h2>

                <div className='lg:w-[50%] w-[90%] border-dashed border-r-4 border-t-4 border-b-4 border-white text-white bg-neutral-900 px-[2.5vw] py-[12vh] lg:text-[6vw] text-[9vw] mx-auto lg:my-0 my-[vh] font-[extrabold] '>
                    <Parallax
                    //  opacity={['1.5', '0']}
                    >
                        <div className='drop-shadow-[5px_5px_5px_var(--tw-shadow-color)] shadow-[#FF9800]'>
                            <MaskText text="Let's discuss" />
                            <MaskText text="your ideas!" />
                        </div>
                    </Parallax>
                    <div className='w-fit mx-auto my-auto'>
                        <a href='#contact'
                            class="text-neutral-100 hover:text-[#FF9800] backdrop-blur-lg bg-gradient-to-tr from-transparent via-[rgba(121,121,121,0.16)] to-transparent rounded-md py-2 px-6 shadow shadow-neutral-400 hover:shadow-neutral-50 duration-700 lg:text-[1.2vw] text-[5vw] font-[lightpoppins]"
                        >
                            Let's Talk
                        </a>
                    </div>

                </div>
            </div>



        </ParallaxProvider>
    )
}
export default Body
